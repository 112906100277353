import { useRouter } from "next/navigation";

const Camera = ({ city, title, snapshot, isBorder = false, cityId = null, cameraId = null, style = "" }) => {
    const router = useRouter();

    const getCameraRoute = () => {
        let route = "/kamere";
        if (cityId || cameraId) {
            route += "?";
            if (cityId) {
                route += `city=${cityId}`;
                if (cameraId) {
                    route += `&camera=${cameraId}`;
                }
            } else if (cameraId) {
                route += `camera=${cameraId}`;
            }
        }
        return route;
    }

    const getBorderRoute = () => {
        let route = "/granicni-prijelazi";
        if (cityId) {
            route += `?location=${cityId}`;
        }
        return route;
    }

    return (
        <div className="text-[11px] md:text-xs m-2 cursor-pointer" onClick={() => router.push(!isBorder ? getCameraRoute() : getBorderRoute())}>
            <div className="flex flex-row items-center">
                <p className="font-semibold mr-1.5">{city}</p>
                <p>{title}</p>
            </div>
            <img className={style} src={snapshot} alt={`${city} Camera Snapshot`} />
        </div>
    );
}

export default Camera;
