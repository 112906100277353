const JuggleMaxMinTemp = (weatherData, jadrankoHourlyData, day) => {
    const defaultMax = weatherData?.data?.weather[day]?.maxtempC;
    const defaultMin = weatherData?.data?.weather[day]?.mintempC;

    if (jadrankoHourlyData.length > 0) {
        //get jadranko data related to the day, ignore null values
        const relevantJadrankoData = jadrankoHourlyData?.[day]?.filter((data) => data !== null);

        let max = 999;
        let min = -999;

        //for each index in relevantJadrankoData, access the first index and compare it to max and min
        for(let i = 0; i < relevantJadrankoData.length; i++) {
            const temp = relevantJadrankoData[i][0].temp;

            if (temp === "xxx"){
                return [defaultMax, defaultMin];
            }

            if (temp < max) {
                max = temp;
            }

            if (temp > min) {
                min = temp;
            }
        }

        //i dont know why, but the values are reversed, so i return max as min and min as max
        return [min, max];
    } else {
        return [defaultMax, defaultMin];

    }
}

export default JuggleMaxMinTemp;