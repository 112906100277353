'use client';
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";

const CitiesMarquee = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/marquee-query');
                const data = await response.json();
                setData(data.result);
            } catch (error) {
                console.error("Error fetching weather data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <Marquee pauseOnHover={true}>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/zagreb" className="font-semibold">{data?.Zagreb}° Zagreb</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/osijek" className="font-semibold">{data?.Osijek}° Osijek</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/split" className="font-semibold">{data?.Split}° Split</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/dubrovnik" className="font-semibold">{data?.Dubrovnik}° Dubrovnik</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/rijeka" className="font-semibold">{data?.Rijeka}° Rijeka</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/sibenik" className="font-semibold">{data?.Sibenik}° Šibenik</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/varazdin" className="font-semibold">{data?.Varazdin}° Varaždin</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/pula" className="font-semibold">{data?.Pula}° Pula</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/zadar" className="font-semibold">{data?.Zadar}° Zadar</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/slavonski-brod" className="font-semibold">{data?.SlavonskiBrod}° Slavonski Brod</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/knin" className="font-semibold">{data?.Knin}° Knin</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/vukovar" className="font-semibold">{data?.Vukovar}° Vukovar</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>
            <div className="flex flex-row justify-center items-center hover:underline">
                <a tabIndex="-1" href="/hrvatska/umag" className="font-semibold">{data?.Umag}° Umag</a>
                <div className="mx-4 h-[5px] w-[5px] rounded-full bg-[#0287C2]" />
            </div>

        </Marquee>
    )

}

export default CitiesMarquee;