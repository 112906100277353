const croatianCities = {
    "bilogora": "HR002",
    "bjelovar": "HR002",
    "cakovec": "HR002",
    "daruvar": "HR002",
    "koprivnica": "HR002",
    "krapina": "HR002",
    "krizevci": "HR002",
    "sljeme": "HR002",
    "sisak": "HR003",
    "varazdin": "HR002",
    "zagreb": "HR002",
    "osijek": "HR005",
    "pozega": "HR005",
    "slavonskibrod": "HR005",
    "virovitica": "HR005",
    "vukovar": "HR005",
    "zupanja": "HR005",
    "bjelolasica": "HR003",
    "gospic": "HR004",
    "karlovac": "HR003",
    "ogulin": "HR003",
    "parg": "HR004",
    "zavizan": "HR006",
    "biokovo": "HR008",
    "dinara": "HR001",
    "gracac": "HR004",
    "imotski": "HR001",
    "knin": "HR001",
    "sinj": "HR001",
    "umag": "HR006",
    "rovinj": "HR006",
    "pula": "HR006",
    "crikvenica": "HR006",
    "rijeka": "HR006",
    "senj": "HR006",
    "karlobag": "HR006",
    "krk": "HR006",
    "cres": "HR006",
    "rab": "HR006",
    "losinj": "HR006",
    "pag": "HR006",
    "pazin": "HR006",
    "porec": "HR006",
    "zadar": "HR008",
    "sibenik": "HR008",
    "split": "HR008",
    "ploce": "HR008",
    "makarska": "HR008",
    "dubrovnik": "HR007",
    "kornati": "HR008",
    "brac": "HR008",
    "hvar": "HR008",
    "vis": "HR008",
    "korcula": "HR007",
    "lastovo": "HR007",
    "orebic": "HR007",
    "mljet": "HR007",
    "palagruza": "HR007"
};

const MapLocationToDHMZRegion = (location) => {
    //get the code corresponding to the city but make the city lowercase
    
    if(!location || typeof location !== 'string') return null;

    const city = location.toLowerCase();

    return croatianCities[city] || null;

}

export default MapLocationToDHMZRegion;