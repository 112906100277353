const mapWeatherCodeToIcon = (weatherCode, isNight = false) => {
    const weatherIconMap = {
        "01": `${isNight ? "moon" : "vedro"}`,
        "02": `${isNight ? "nightcloudy" : "malooblacno"}`,
        "03": `${isNight ? "nightcloudy" : "umjerenooblacno"}`,
        "04": `${isNight ? "nightcloudy" : "preteznooblacno"}`,
        "05": "potpunooblacno",
        "06": "slabakisa",
        "07": "umjerenakisa",
        "08": "jakakisa",
        "09": "slabasusnjezica",
        "10": "umjerenasusnjezica",
        "11": "jakasusnjezica",
        "12": "slabsnijeg",
        "13": "umjerenisnijeg",
        "14": "jakisnijeg",
        "15": "magla",
        "16": "grmljavinaipljusak",
        "20": "partialcloudwithrain",
        "25": "partialcloudwithsnow",
        "26": "partialcloudwithsleet",
        "28": "partialcloudwithoccasionalshowersandthunderstorms",
        "29": "partialcloudwithfog",
        "30": "umjerenakisa",
        "31": "umjerenakisa",
        "32": "umjerenasusnjezica",
        "33": "umjerenasusnjezica",
        "35": "potpunooblacno", //this line and above is jadranko data
        "113": `${isNight ? "moon" : "vedro"}`,
        "116": `${isNight ? "nightcloudy" : "potpunooblacno"}`,
        "119": "potpunooblacno",
        "122": "potpunooblacno",
        "143": "potpunooblacno",
        "176": "umjerenakisa",
        "179": "umjerenisnijeg",
        "182": "umjerenakisa",
        "185": "umjerenakisa",
        "200": "grmljavinaipljusak",
        "227": "umjerenisnijeg",
        "230": "umjerenisnijeg",
        "248": "potpunooblacno",
        "260": "potpunooblacno",
        "263": "umjerenakisa",
        "266": "umjerenakisa",
        "281": "umjerenakisa",
        "284": "umjerenakisa",
        "293": "umjerenakisa",
        "296": "umjerenakisa",
        "299": "umjerenakisa",
        "302": "umjerenakisa",
        "305": "umjerenakisa",
        "308": "umjerenakisa",
        "311": "umjerenakisa",
        "314": "umjerenakisa",
        "317": "umjerenisnijeg",
        "320": "umjerenisnijeg",
        "323": "umjerenisnijeg",
        "326": "umjerenisnijeg",
        "329": "umjerenisnijeg",
        "332": "umjerenisnijeg",
        "335": "umjerenisnijeg",
        "338": "umjerenisnijeg",
        "350": "umjerenisnijeg",
        "353": "umjerenakisa",
        "356": "umjerenakisa",
        "359": "umjerenakisa",
        "362": "umjerenakisa",
        "365": "umjerenakisa",
        "368": "umjerenisnijeg",
        "371": "umjerenisnijeg",
        "374": "umjerenisnijeg",
        "377": "umjerenisnijeg",
        "386": "grmljavinaipljusak",
        "389": "grmljavinaipljusak",
        "392": "grmljavinaipljusak",
        "395": "grmljavinaipljusak",
    };

    const defaultIcon = "potpunooblacno";

    return ("/WeatherIcons/" + weatherIconMap[weatherCode] + ".svg") || defaultIcon;
};

export default mapWeatherCodeToIcon;
