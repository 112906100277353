import Image from "next/image";

const WidgetPromo = () => {
    return (
        <div className="flex flex-col">
            <div className='flex flex-row items-center pb-4'>
                <p className="text-xs md:text-sm md-12 font-semibold">vrijeme.net - <b>Widget</b></p>
            </div>
            <div className="md:flex md:items-center xl:items-center h-full">
                <div className="md:flex-1 md:mr-4 space-y-4 lg:space-y-5 xl:space-y-6">
                    <p className="text-[#0287C2] text-xl font-semibold">Želite vremensku prognozu na svom webu?</p>
                    <p className="text-sm">Odaberite lokaciju i dizajn koji vam odgovara, te jednostavno instalirajte.</p>
                    <p className="text-sm">Widget prikazuje trenutne vremenske uvjete te trodnevnu vremensku prognozu za odabranu lokaciju.</p>
                </div>
                <div className="flex flex-col items-center justify-center md:w-1/2 pt-4 md:pt-0">
                    <a href='/widget'>
                        <Image src="/widgetpicture.png" alt="Widget promo" width={300} height={250} />
                    </a>
                    <a className="mt-4 cursor-pointer text-[#0287C2] hover:text-[#02a7f1] transition duration-200 underline text-sm" href='/widget'>Saznaj više</a>
                </div>
            </div>
        </div>
    );
}

export default WidgetPromo;
