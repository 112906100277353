'use client'

import Link from 'next/link';
import React, { useState, useEffect } from 'react';

const MonthWeatherDisplay = () => {
    const [infoToday, setInfoToday] = useState(null);

    const getFormattedDate = () => {
        const now = new Date();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${day}.${month}.`;
    }

    const stationImageMapping = {
        '1': '/nepovoljniuvjeti.svg',
        '2': '/neutralno.svg',
        '3': '/povoljniuvjeti.svg',
    };

    const getImageSrc = (stationValue) => {
        return stationImageMapping[stationValue] || null;
    };


    useEffect(() => {
        const getInfo = async () => {
            try {
                const response = await fetch('/api/bioprognoza');
                const data = await response.json();

                setInfoToday(data);

            } catch (error) {
                console.error('Error fetching bioprognoza data:', error);
            }
        };

        getInfo();
    }, []);

    return (
        <div className="p-4 w-full h-full">
            <div className='flex flex-row items-center'>
                <p className="text-xs md:text-sm font-semibold md-12 font-semibold">Bioprognoza za Hrvatsku - {getFormattedDate()}</p>
                <a href="https://meteo.hr/" className="ml-auto text-xs text-gray-800 hover:underline">Powered by <b>DHMZ</b></a>
            </div>

            <div className="flex flex-col sm:flex-row pt-8 pb-4 text-xs h-full">
                <div className='w-[50%]'>
                    <div className="flex flex-row items-center py-2">
                        <img src="/povoljniuvjeti.png" alt="Good weather" className="md:ml-2 mr-2 w-[35px] h-[35px]" />
                        <p>Povoljni uvjeti</p>
                    </div>
                    <div className="flex flex-row items-center py-2">
                        <img src="/neutralno.png" alt="Ok weather" className="md:ml-2 mr-2 w-[35px] h-[35px]" />
                        <p>Neutralno</p>
                    </div>
                    <div className="flex flex-row items-center py-2">
                        <img src="/nepovoljniuvjeti.png" alt="Bad weather" className="md:ml-2 mr-2 w-[35px] h-[35px]" />
                        <p>Nepovoljni uvjeti</p>
                    </div>
                    <div className='flex'>
                        <Link className="mt-4 cursor-pointer text-[#0287C2] hover:text-[#02a7f1] transition duration-200 underline pl-8 text-sm" href='/bioprognoza'>Saznaj više</Link>
                    </div>
                </div>
                <div className="flex items-center h-[auto] xl:w-full my-auto mx-auto justify-center relative">
                    <img className="max-h-[300px]" src="/hr.svg" style={{ fill: '#0287C2' }} alt="Croatia Map" />
                    {infoToday && (
                        <>
                            <div style={{ height: '35px', width: '35px', position: 'absolute', left: '70%', top: '25%' }}>
                                <img alt="Istocna" src={getImageSrc(infoToday.stations?.istocna)} />
                            </div>
                            <div style={{ height: '35px', width: '35px', position: 'absolute', left: '45%', top: '15%' }}>
                                <img alt="Sredisnja" src={getImageSrc(infoToday.stations?.sredisnja)} />
                            </div>
                            <div style={{ height: '35px', width: '35px', position: 'absolute', left: '35%', top: '50%' }}>
                                <img alt="Gorska" src={getImageSrc(infoToday.stations?.gorska)} />
                            </div>
                            <div style={{ height: '35px', width: '35px', position: 'absolute', left: '15%', top: '30%' }}>
                                <img alt="Sjevernijadran" src={getImageSrc(infoToday.stations?.sjevernijadran)} />
                            </div>
                            <div style={{ height: '35px', width: '35px', position: 'absolute', left: '50%', top: '70%' }}>
                                <img alt="Juznijadran" src={getImageSrc(infoToday.stations?.juznijadran)} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MonthWeatherDisplay;