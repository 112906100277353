'use client'

import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useRouter } from "next/navigation";
import '../styles/components/citysearch.css'
import removeAccents from 'remove-accents';

const CitySearch = ({ color }) => {
    const [searchText, setSearchText] = useState('Pretraži prognozu po lokaciji');
    const [didFirstSearch, setDidFirstSearch] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [timer, setTimer] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const router = useRouter();

    const getColors = (color) => {
        switch (color) {
            case 'white':
                return ['#FFFFFF', '#087CB4'];
            case 'blue':
                return ['#087CB4', '#FFFFFF'];
            default:
                return ['#087CB4', '#FFFFFF'];
        }
    }

    const handleInputChange = (e) => {
        const inputText = e.target.value;
        setSearchText(inputText);
        setDidFirstSearch(true);

        if (inputText.length >= 3) {
            setSearchResults([]);
            setIsSearching(true);

            if (timer) {
                clearTimeout(timer);
            }

            const newTimer = setTimeout(async () => {
                console.log(`Searching for: ${inputText}`);
                const response = await fetch(`/api/city-search?query=${inputText}`);
                const data = await response.json();

                setSearchResults(data.result);
                setIsSearching(false);
            }, 600);

            setTimer(newTimer);
        } else {
            setSearchResults([]);
            setIsSearching(false);
            if (timer) {
                clearTimeout(timer);
            }
        }
    };

    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    const handleResultClick = (result) => {
        const query = `/${result.localizedCountry.replaceAll(' ', '-')}/${result.city.replace(' ', '-')}`.toLowerCase();
        router.push(removeAccents(query));
        setDidFirstSearch(false);
        setSearchText('Pretraži prognozu po lokaciji');
    };

    return (
        <section className="relative">
            <div className='flex space-x-1 rounded p-4' style={{
                backgroundColor: getColors(color)[0],
                transition: 'background-color 0.3s ease',
                position: 'relative'
            }}>
                <Icon icon="material-symbols:search" style={{ color: getColors(color)[1], fontSize: '24px' }} />
                <input
                    type="text"
                    onChange={handleInputChange}
                    placeholder="Pretraži prognozu po lokaciji"
                    className={`border-none outline-none bg-transparent w-full font-semibold ${color === 'white' ? 'input-blue' : 'input-white'}`}
                    style={{
                        fontSize: '16px',
                        lineHeight: '20px',
                        letterSpacing: '0.25px',
                        color: getColors(color)[1],
                    }}

                />
                {(searchText?.length >= 3 && didFirstSearch) && (
                    <div className="absolute bg-white p-2 rounded-md shadow-md mt-10 text-black" style={{ zIndex: 999, width: 'calc(100% - 40px)', left: '16px', right: '20px' }}>
                        {isSearching ? (
                            <div className="p-2">
                                <p>Pretraživanje...</p>
                            </div>

                        ) : searchResults.length > 0 ? (
                            searchResults.map((result, index) => (
                                <div key={index} className="p-2 cursor-pointer border-b" onClick={() => handleResultClick(result)}>
                                    <p>{result.localizedCity}</p>
                                    <p className="text-slate-700 text-xs">{result.localizedDisplay.split(',').slice(1).join(',').trim()}</p>

                                </div>
                            ))
                        ) : (
                            <div className="p-2">
                                <p>Nema rezultata pretraživanja.</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
};

export default CitySearch;
